import { Box, Button, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ text, setText, searchSvgs, setLastVisibleSvg }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
                placeholder="Search"
                variant="standard"
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setLastVisibleSvg(null);
                        searchSvgs(text, null);
                    }
                }}
                sx={{
                    backgroundColor: '#2c2c2c',
                    py: 1,
                    px: 2,
                    borderRadius: 2,
                    width: {
                        xs: 200,
                        md: 400,
                    },
                    '& input': { color: 'white' },
                    '& label': { color: 'lightgrey' },
                    '& .MuiInput-underline:before': { display: 'none' },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { display: 'none' },
                    '& .MuiInput-underline:after': { display: 'none' },
                }}
            />
            <Button 
                variant="contained" 
                onClick={() => {
                    setLastVisibleSvg(null);
                    searchSvgs(text, null);
                }}
                sx={{
                    backgroundColor: "orange",
                    color: "black",
                    height: 50,
                    width: 40,
                    ml: 2,
                    borderRadius: 2,
                    '&:hover': { backgroundColor: "orange" },
                }}
            >
                <SearchIcon sx={{ fontWeight: 'bold' }} />
            </Button>
        </Box>
    );
}

export default Search;