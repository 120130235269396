export const CATEGORIES = [
    'Add',
    'Close',
    'Phone',
    'Folders',
    'Video',
    'Img',
    'Mobile',
    'Date',
    'Calendar',
    'Box',
    'Map',
    'File',
    'Load',
    'Check',
    'Navigate',
    'Favorite',
    'View',
    'Download',
    'Lock',
    'Send',
    'Message',
    'Remove',
    'Sign',
    'Alarm',
    'User',
    'Group',
    'Time',
    'Setting',
    'Shop',
    'Edit',
    'Question',
    'Info',
    'Home',
    'Basket',
    'Money',
    'Trash',
    'Filter',
    'Status',
    'Chat',
    'Book',
    'Desk',
    'Chart',
    'Flag',
    'Sort',
    'Switch',
    'Sound',
    'Cloud',
    'Arrow',
]

export const PRO_FEATURES = [{
    title: 'Premium icons',
    description: 'Get access to hundreds of premium designed icons.',
}, {
    title: 'New icons monthly',
    description: 'Get free access to new Pro icons, added on a monthly basis.',
}, {
    title: 'Unlimited downloads',
    description: 'Download icons as often as you want. We won\'t ever charge you extra or limit you.',
}]; 

// SANDBOX
// export const PAYPAL_CLIENT_ID = 'AZ4TibJ0crj-x9xz7CpaKqcAEcUY7j3ehTm4_p40wdAivXtNBZ3Vp_QUQpCaV-oNhSCnPWeSSnp2h1-f';

// LIVE
export const PAYPAL_CLIENT_ID = 'AZROw1jwWJeEGntoZj84Hi0hr8IPNxHhwRYjs_Cut68VFr0z3CBtqF7HgadUKlpZRzE0IybAqA3oHBaT';

export const NUM_RESULTS = 50;
