import { Box, Button, Chip, Drawer, Link, Stack, Typography } from '@mui/material';
import { adjustSvgDimensions } from '../utils';

const IconDrawer = ({isDrawerOpen, setIsDrawerOpen, selectedSvg, user, setIsModalOpen }) => {
  return (
    <Drawer
      anchor='right'
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <Box
        sx={{ width: 250, padding: 2 }}
        role="presentation"
      >
        {/* Assuming SVG is safe to directly inject. For production, ensure SVGs are sanitized to prevent XSS attacks. */}
        <div dangerouslySetInnerHTML={{ __html: adjustSvgDimensions(selectedSvg?.svg, 250, 250) }} />
        <Stack direction="row" spacing={1}>
          {
            selectedSvg?.pro && <Chip label='Pro' color='primary' />
          }
          {
            selectedSvg?.tags.map(tag => (
              <Chip key={tag} label={tag} />
            ))
          }
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            sx={{
              backgroundColor:'orange',
              '&:hover': { backgroundColor: "orange"},
              color:'black',
              m: 2,
              height: 30,
              width: '100%',
              fontSize: 12,
              fontWeight: 'bold',
              borderRadius: 2,
            }}
            onClick={() => {
              if (selectedSvg?.pro && !user) {
                setIsModalOpen(true);
              } else {
                navigator.clipboard.writeText(selectedSvg?.svg)
              }
            }}
          >Copy</Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            sx={{
              backgroundColor:'orange',
              '&:hover': { backgroundColor: "orange"},
              color:'black',
              mx: 2,
              mb: 2,
              height: 30,
              width: '100%',
              fontSize: 12,
              fontWeight: 'bold',
              borderRadius: 2,
            }}
            onClick={() => {
              if (selectedSvg?.pro && !user) {
                setIsModalOpen(true);
              } else {
                const element = document.createElement('a');
                const file = new Blob([selectedSvg?.svg], { type: 'image/svg+xml' });
                element.href = URL.createObjectURL(file);
                element.download = `${selectedSvg?.name}.svg`;
                document.body.appendChild(element);
                element.click();
              }
            }}
          >Download</Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='caption' textAlign='center'>
            <Link href={selectedSvg?.creator} target="_blank" rel="noopener noreferrer">
              Credits
            </Link>
          </Typography>
        </Box>
      </Box>
    </Drawer>
  )
}

export default IconDrawer;