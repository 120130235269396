import { Box, Typography, Grid } from '@mui/material';
import { CATEGORIES } from '../constants';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <Box sx={{
            p: 10,
            backgroundColor: '#2c2c2c',
        }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant='h5' fontWeight='bold'>
                    <Link to='/' style={{ color: 'white', textDecoration: 'none' }}>
                        Figma Icons
                    </Link>
                </Typography>
            </Box>

            <Grid container>
                {
                    CATEGORIES.map((category, index) => (
                        <Grid item xs={6} md={4} lg={3} key={index}>
                            <Link to={'/categories/' + category} style={{ color: 'white', textDecoration: 'none' }}>
                                <Typography fontWeight='bold' sx={{ color: 'white', fontSize: 15, display: 'inline' }}>
                                    {category + ' '}
                                </Typography>
                                <Typography sx={{ color: 'white', fontSize: 15, display: 'inline', mr: 1 }}>
                                    Icons
                                </Typography>
                            </Link>
                        </Grid>
                    ))
                }
            </Grid>

            <Box sx={{ mt: 8 }}>
                <Typography sx={{ color: 'white', textAlign: 'center' }}>
                    © {new Date().getFullYear()} Figma Icons. All rights reserved
                </Typography>
            </Box>
        </Box>
    )
}

export default Footer;