import { useState } from 'react';
import { Box } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Paywall from './components/Paywall';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Categories from './pages/Categories';
import Help from './components/Help';
import { AuthProvider } from './context/AuthContext';


function App(){
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Box sx={{
      backgroundColor: 'black',
      color: 'white',
    }}>
      <BrowserRouter>
        <AuthProvider>
          <Header setIsModalOpen={setIsModalOpen} />
          <Routes>
            <Route path="/" element={<Home setIsModalOpen={setIsModalOpen} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/:category" element={<Home />} />
          </Routes>
          <Paywall isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          <Footer />
          <Help />
        </AuthProvider>
      </BrowserRouter>
    </Box>
  );
}

export default App;
