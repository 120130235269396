import { Box, Link } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const Help = () => {
    return (
        <Link href="mailto:figmaicons@gmail.com" target="_blank" rel="noopener noreferrer">
            <Box sx={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                margin: 2,
                px: 1.5,
                pb: 1,
                pt: 1.5,
                borderRadius: '50%',
                backgroundColor: 'orange',
            }}>
                <ChatBubbleIcon style={{color: 'black'}} />
            </Box>
        </Link>
    )

}

export default Help;