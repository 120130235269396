import { useState, useEffect, useContext } from 'react';
import { auth } from '../firebase/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Box, Typography, TextField, Button } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';


export default function Login() {
    const navigate = useNavigate();
    const { user, setUser } = useContext(AuthContext);
    
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    const handleLogin = () => {
        setIsLoading(true);
        signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
            setUser(userCredential.user);
            navigate('/');
        }).catch((error) => {
            console.log(error);
            setMessage('Invalid email or password');
        });
        setIsLoading(false);
    }

    useEffect(() => {
        console.log('user', user);    
    }, [user])

    useEffect(() => {
        document.title = 'Login | Figma Icons';
        document.querySelector('meta[name="description"]').setAttribute('content', 'Login to Figma Icons to access pro features.');
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 20,
                pb: 30,
            }}
        >
            <Typography variant='h5' sx={{fontWeight: 'bold'}}>Login</Typography>
            <Typography
                variant="body"
                sx={{
                    color: 'lightgrey',
                    mb: 2,
                }}
                >
                (Pro users only)
            </Typography>

            <TextField
                placeholder="Email"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                    backgroundColor: '#2c2c2c',
                    py: 1,
                    px: 2,
                    borderRadius: 2,
                    width: {
                        xs: 200,
                        sm: 400,
                    },
                    '& input': { color: 'white' },
                    '& label': { color: 'lightgrey' },
                    '& .MuiInput-underline:before': { display: 'none' },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { display: 'none' },
                    '& .MuiInput-underline:after': { display: 'none' },
                    '& input:-webkit-autofill': {
                        '-webkit-box-shadow': '0 0 0 30px #2c2c2c inset !important',
                        '-webkit-text-fill-color': 'white !important',
                    },
                }}
            />

            <TextField
                placeholder="Password"
                variant="standard"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                    backgroundColor: '#2c2c2c',
                    py: 1,
                    px: 2,
                    my: 2,
                    borderRadius: 2,
                    width: {
                        xs: 200,
                        sm: 400,
                    },
                    '& input': { color: 'white' },
                    '& label': { color: 'lightgrey' },
                    '& .MuiInput-underline:before': { display: 'none' },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { display: 'none' },
                    '& .MuiInput-underline:after': { display: 'none' },
                    '& input:-webkit-autofill': {
                        '-webkit-box-shadow': '0 0 0 30px #2c2c2c inset !important',
                        '-webkit-text-fill-color': 'white !important',
                    },
                }}
            />

            <Typography sx={{mb: 2}}>
                {message}
            </Typography>

            <Button
                sx={{
                    backgroundColor: 'orange',
                    '&:hover': { backgroundColor: "orange" },
                    color: 'black',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    borderRadius: 2,
                }}
                disabled={isLoading}
                onClick={() => handleLogin()}
            >
                {isLoading ? 'Loading...' : 'Login'}
            </Button>
        </Box>
    );
}