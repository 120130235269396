import { Box, Typography, Grid } from '@mui/material';
import { CATEGORIES } from '../constants'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const Categories = () => {
  useEffect(() => {
    document.title = 'All Categories | Figma Icons';
    document.querySelector('meta[name="description"]').setAttribute('content', 'A list of all Figma icon categories listed on our site. Download free Figma icons for your next project.');
  }, []);

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        py: 4,
      }}
      >
        <Box sx={{textAlign: 'center'}}>
          <Typography variant='h5' fontWeight='bold'>
            All Categories
          </Typography>
          <Typography variant='body' sx={{ color: 'lightgray' }}>
            A list of all Figma icons listed on our site.
          </Typography>
        </Box>
      </Box>
      <Grid container sx={{justifyContent: 'center'}}>
        {
          CATEGORIES.map((category) => (
            <Grid item xs={6} md={4} lg={3}>
              <Link to={'/categories/' + category} style={{ color: 'white', textDecoration: 'none' }}>
                <Box
                  sx={{
                    backgroundColor: '#2c2c2c',
                    p: 2,
                    m: 1,
                    borderRadius: 2
                  }}
                >
                  <Typography variant='h7' fontWeight='bold' sx={{ color: 'white' }}>
                    {category + ' '}
                  </Typography>
                  <Typography variant='h7' sx={{ color: 'white' }}>
                    Icons
                  </Typography>
                </Box>
              </Link>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

export default Categories;