import { useState, useEffect, useContext } from 'react';
import { Box, Typography, Grid, Button, Link } from '@mui/material';
import IconPreview from '../components/IconPreview';
import Search from '../components/Search';
import IconDrawer from '../components/IconDrawer';
import { db } from '../firebase/firebase';
import { collection, getDocs, query, where, startAfter, limit } from "firebase/firestore";
import LoadingSpinner from '../components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { NUM_RESULTS } from '../constants';


function Home({setIsModalOpen}) {
  const { user } = useContext(AuthContext);

  const [text, setText] = useState('');
  const [svgs, setSvgs] = useState([]);
  const [selectedSvg, setSelectedSvg] = useState(null);
  const [lastVisibleSvg, setLastVisibleSvg] = useState(null);
  const [atBottom, setAtBottom] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const category = useParams().category;

  const searchSvgs = async (text, lastVisibleSvg) => {
    setIsLoading(true);
    if (!lastVisibleSvg) {
      setSvgs([]);
      setAtBottom(false);
    }
    let q;
    if (text) {
      const tags = text.toLowerCase().split(' ');
      if (lastVisibleSvg) {
        q = query(collection(db, "icons"), where("tags", "array-contains-any", tags), startAfter(lastVisibleSvg), limit(NUM_RESULTS));
      } else {
        q = query(collection(db, "icons"), where("tags", "array-contains-any", tags), limit(NUM_RESULTS));
      }
    } else {
      if (lastVisibleSvg) {
        q = query(collection(db, "icons"), startAfter(lastVisibleSvg), limit(NUM_RESULTS));
      } else {
        q = query(collection(db, "icons"), limit(NUM_RESULTS));
      }
    }

    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length === 0) {
      setIsLoading(false);
      setAtBottom(true);
      return;
    }
    let data = []
    querySnapshot.forEach((doc) => {
      let svg = doc.data();
      data.push(svg);
    });
    if (lastVisibleSvg) {
      setSvgs(prevSvgs => [...prevSvgs, ...data]);
    } else {
      setSvgs(data);
    }
    setLastVisibleSvg(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setIsLoading(false);
  }

  useEffect(() => {
    if (category)  {
      document.title = `Free Figma ${category} Icons`;
      document.querySelector('meta[name="description"]')
        .setAttribute('content', `Copy and paste Figma ${category} icons in Figma with the most efficient way to build designs. Download free Figma ${category} icons for your next project.`);
      searchSvgs(category);
    } else {
      document.title = 'Free Figma Icon Library | Figma Icons';
      document.querySelector('meta[name="description"]')
        .setAttribute('content', 'We\'ve curated the best Figma icons in one place. Discover, copy and paste Icons in Figma with the most efficient way to build designs. Get all the essentials for any design project in one place.');
      searchSvgs();
    }
  }, [category]);

  // infinite scroll

  // const handleScroll = useCallback(() => {
  //   if ((window.innerHeight + document.documentElement.scrollTop) < (document.documentElement.offsetHeight * 0.9) || isLoading) return;
  //   if (atBottom) return;
  //   searchSvgs(text, lastVisibleSvg);
  // }, [isLoading, searchSvgs]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [handleScroll]);

  // set cursor at search bar on pressing command+k

  return (
    <Box>
      <Grid container sx={{ p: 4 }}>
        {category ? (
          <Grid item sm={12} md={8}>
            <Typography variant='h5' fontWeight='bold' sx={{ color: 'orange' }}>Figma {category} Icons</Typography>
            <Typography variant='body1' sx={{ mt: 1, maxWidth: 600, color: 'lightgray' }}>
            This collection contains a variety of free Figma icons that can be used in your next project
            </Typography>
          </Grid>
        ) : (
          <Grid item sm={12} md={8}>
            <Typography variant='h5' fontWeight='bold' sx={{ color: 'orange' }}>Free Figma Icon Library</Typography>
            <Typography variant='body1' sx={{ mt: 1, maxWidth: 600, color: 'lightgray' }}>
              We've curated the best Figma icons in one place. Discover, copy and paste Icons in Figma with the most efficient way to build designs. Get all the essentials for any design project in one place.
            </Typography>
          </Grid>
        )}

        <Grid item sm={0} md={4} sx={{display: { xs: 'none', md: 'flex'}, flexDirection: 'column', justifyContent: 'center'}}>
          <Box sx={{mb: 1}}>
            <Typography variant='body' sx={{ color: 'lightgray' }}>
              Simply copy paste any icon<br />
            </Typography>
          </Box>
          <Box sx={{mb: 1}}>
            <Typography variant='body' sx={{ color: 'lightgray' }}>
              <Link href="https://creativecommons.org/licenses/by/4.0/"  target="_blank" rel="noopener noreferrer" style={{color: 'lightgray'}}>CC BY 4.0 license</Link><br />
            </Typography>
          </Box>
          <Box>
            <Typography variant='body' sx={{ color: 'lightgray' }}>
              Tool by <Link href="https://twitter.com/HaiderShawl"  target="_blank" rel="noopener noreferrer" style={{color: 'lightgray'}}>Haider Shawl</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {
        !category && (
          <Search text={text} setText={setText} searchSvgs={searchSvgs} setLastVisibleSvg={setLastVisibleSvg} />
        )
      }

      <Grid container sx={{ px: 8, py: 4, display: 'flex', justifyContent: 'center' }}>
        {svgs.map(svg => (
          <Box
            key={svg.id}
            onClick={() => {
              setSelectedSvg(svg);
              setIsDrawerOpen(true);
            }}
          >
            <IconPreview icon={svg.svg} />
          </Box>
        ))}
      </Grid>

      {
        svgs.length === 0 && !isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
            <Typography variant='body1'>No results found</Typography>
          </Box>
        )
      }

      {isLoading && <LoadingSpinner />}

      {!isLoading && !atBottom && (svgs.length % NUM_RESULTS === 0) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <Button
            sx={{
              backgroundColor: 'orange',
              '&:hover': { backgroundColor: "orange" },
              color: 'black',
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
            onClick={() => searchSvgs(text, lastVisibleSvg)}
          >
            Load More
          </Button>
        </Box>
      )}

      <IconDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} selectedSvg={selectedSvg} user={user} setIsModalOpen={setIsModalOpen} />
    </Box>
  );
}

export default Home;
