import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', py: 15 }}>
      <CircularProgress sx={{color: 'orange'}} />
    </Box>
  )
}

export default LoadingSpinner;
