import axios from "axios";
import { useState, useContext } from "react";
import { Box, Modal, Typography, TextField, Button } from "@mui/material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PRO_FEATURES, PAYPAL_CLIENT_ID } from "../constants";
import { db, auth } from "../firebase/firebase";
import { Link } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from "firebase/auth";
import logo from "../images/logo.png";

const Paywall = ({ isModalOpen, setIsModalOpen }) => {
  const { setUser } = useContext(AuthContext);

  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleNext = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setMessage('Please enter a valid email');
      return;
    } else if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email');
      return;
    } else if (!password) {
      setMessage('Please enter a valid password');
      return;
    } else if (password.length < 6) {
      setMessage('Password must be at least 6 characters long');
      return;
    }

    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    if (signInMethods.length > 0) {
      setMessage('Email is already in use');
      return;
    }

    setMessage('');
    setActiveStep(1);
  }

  const createOrder = () => {
    return axios.get('https://figma-icons.gamerhaider7.workers.dev/api/create-new-order').then((response) => {
      console.log(response.data);
      return response.data.id;
    }).catch((error) => {
      console.error(error);
    });
  }

  const onApprove = async (data) => {
    data['userEmail'] = email;
    data['createdAt'] = new Date().toISOString();
    const paymentsCollectionRef = collection(db, "payments");
    await addDoc(paymentsCollectionRef, data);

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    setUser(user);

    setIsModalOpen(false);
  }

  const onError = (err) => {
    console.log("Error: ", err);
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setActiveStep(0);
        setIsModalOpen(false);
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          p: 6,
          backgroundColor: 'black',
          border: '2px solid orange',
          height: '75vh',
          width: '60vw',
          borderRadius: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <img src={logo} alt="logo" height='80px' width='80px' />
        <Typography
          variant="h5"
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Upgrade to access the full library
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'lightgrey',
          }}
        >
          Go beyond your limits, get exclusive access to the Pro features
        </Typography>


        {
          activeStep === 0 && (
            <>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 'bold',
                  my: 2,
                }}
              >
                Please provide details to create an account
              </Typography>
              <TextField
                placeholder="Email"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  backgroundColor: '#2c2c2c',
                  py: 1,
                  px: 2,
                  borderRadius: 2,
                  width: {
                    xs: 200,
                    sm: 400,
                  },
                  '& input': { color: 'white' },
                  '& label': { color: 'lightgrey' },
                  '& .MuiInput-underline:before': { display: 'none' },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': { display: 'none' },
                  '& .MuiInput-underline:after': { display: 'none' },
                  '& input:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 30px #2c2c2c inset !important',
                    '-webkit-text-fill-color': 'white !important',
                  },
                }}
              />

              <TextField
                placeholder="Password"
                variant="standard"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  backgroundColor: '#2c2c2c',
                  py: 1,
                  px: 2,
                  my: 2,
                  borderRadius: 2,
                  width: {
                    xs: 200,
                    sm: 400,
                  },
                  '& input': { color: 'white' },
                  '& label': { color: 'lightgrey' },
                  '& .MuiInput-underline:before': { display: 'none' },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': { display: 'none' },
                  '& .MuiInput-underline:after': { display: 'none' },
                  '& input:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 30px #2c2c2c inset !important',
                    '-webkit-text-fill-color': 'white !important',
                  },
                }}
              />

              <Typography sx={{ mb: 1, color: 'white' }}>
                {message}
              </Typography>

              <Button
                sx={{
                  backgroundColor: 'orange',
                  '&:hover': { backgroundColor: "orange" },
                  color: 'black',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  borderRadius: 2,
                  mb: 2,
                }}
                onClick={() => handleNext()}
              >
                Continue
              </Button>
            </>
          )
        }

        {
          activeStep === 1 && (
            <>
              <Typography
                sx={{
                  color: "orange",
                  fontWeight: 'bold',
                  my: 2,
                }}
              >
                Unlock for $49 (one-time payment)
              </Typography>
              <PayPalScriptProvider options={{ clientId: PAYPAL_CLIENT_ID }}>
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                />
              </PayPalScriptProvider>
              <Typography
                variant="caption"
                sx={{
                  color: 'white',
                  mb: 2,
                  cursor: 'pointer',
                }}
                onClick={() => setActiveStep(0)}
              >
                Go Back
              </Typography>
            </>
          )
        }

        <Box
          sx={{
            px: 4,
            backgroundColor: '#2c2c2c',
            maxWidth: 400,
            borderRadius: 3,
          }}
        >
          {
            PRO_FEATURES.map((feature, index) => (
              <Box key={index} sx={{ my: 3 }}>
                <Typography
                  variant="h7"
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'lightgrey',
                  }}
                >
                  {feature.description}
                </Typography>
              </Box>
            ))
          }
        </Box>

        <Typography sx={{ mt: 2, color: 'lightgrey' }}>
          Already have an account? <Link onClick={() => setIsModalOpen(false)} to='/login' style={{ color: 'orange', cursor: 'pointer' }}>Login</Link>
        </Typography>
      </Box>
    </Modal>
  )
}

export default Paywall;