import React, { useContext } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { auth } from '../firebase/firebase';
import { signOut } from 'firebase/auth';

const Header = ({ setIsModalOpen }) => {
  const { user } = useContext(AuthContext);

  return (
    <Box sx={{
      p: 2,
      borderBottom: '1px solid #2c2c2c',
      display: 'flex',
      alignItems: 'center',
    }}>
      <Typography variant='h7' fontWeight='bold'>
        <Link to='/' style={{ color: 'white', textDecoration: 'none' }}>
          Figma Icons
        </Link>
      </Typography>
      <Box sx={{ ml: 'auto' }}>
        <Link to='/categories' style={{ color: 'white', textDecoration: 'none' }}>
          <Button
            sx={{
              backgroundColor: '#2c2c2c',
              '&:hover': { backgroundColor: "#2c2c2c" },
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              borderRadius: 2,
            }}
          >
            Explore
          </Button>
        </Link>

        {
          user ? (
            <Button
              sx={{
                backgroundColor: '#2c2c2c',
                '&:hover': { backgroundColor: "#2c2c2c" },
                color: 'white',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                borderRadius: 2,
                mx: 2,
              }}
              onClick={() => signOut(auth)}
            >
              Logout
            </Button>
          ) : (
            <Link to='/login' style={{ color: 'white', textDecoration: 'none' }}>
              <Button
                sx={{
                  backgroundColor: '#2c2c2c',
                  '&:hover': { backgroundColor: "#2c2c2c" },
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  borderRadius: 2,
                  mx: 2,
                }}
              >
                Login
              </Button>
            </Link>
          )
        }

        {
          !user && (
            <Button
              sx={{
                backgroundColor: 'orange',
                '&:hover': { backgroundColor: "orange" },
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                borderRadius: 2,
              }}
              onClick={() => setIsModalOpen(true)}
            >
              Go Pro
            </Button>
          )
        }

      </Box>
    </Box>
  )
}

export default Header;
