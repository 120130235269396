import React from 'react';
import { Box, Grid } from '@mui/material';
import { adjustSvgDimensions } from '../utils';


const IconPreview = ({ icon }) => {
	return (
		<Grid item
			sx={{
				'& > div': {
					transition: 'transform 0.3s',
					'&:hover': {
						transform: 'scale(1.05)',
					},
				},
			}}
		>
			<Box sx={{
				m: 1,
				backgroundColor: 'white',
				width: 120,
				height: 120,
				borderRadius: 2,
				cursor: 'pointer',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
				'&:hover button': {
					display: 'block',
				},
			}}>
				<div dangerouslySetInnerHTML={{ __html: adjustSvgDimensions(icon,70,70) }} />
				{/* <Button
					variant='contained'
					color='primary'
					sx={{
						m: 1,
						p: 0,
						height: 20,
						width: 40,
						fontSize: 10,
						fontWeight: 'bold',
						position: 'absolute',
						top: 0,
						right: 0,
						display: 'none',
					}}
					onClick={() => navigator.clipboard.writeText(icon)}
				>Copy</Button> */}
			</Box>
		</Grid>
	)
}

export default IconPreview;